h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oxanium", sans-serif;
}
p {
  font-family: "Inter", sans-serif;
}
.team .p1 {
  margin: 0 20%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.team h1 {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 3rem;
  padding: 20px;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 65px;
  margin-bottom: 75px;

  flex-wrap: wrap;

  padding: 0 40px;
}

.cards-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 69px;

  flex-wrap: wrap;
}

.cards-container .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 275px;
  height: 370px;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
  transition: transform 0.2s ease-in;
  cursor: pointer;
}

.cards-container-2 .card {
  width: 200px;

  display: flex;
  background-color: var(--text);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.cards-container-2 .card:hover,
.cards-container .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card .card-image {
  width: 300px;
  height: 300px;
  background-color: var(--background);
  border-radius: 200px;
}
.card .card-image-2 {
  width: 169px;
  height: 100%;
  background-color: var(--background);
  border-radius: 8px;
}
.card .card-image img {
  width: 250px;
  height: 250px;
  border-radius: 300px;
}
.card-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-wrap: wrap;
  bottom: 25px;
  width: 180px;
}
.card-content-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: calc(100% - 169px);
}

.card-title {
  font-size: 1.2rem;
  justify-self: center;
  color: var(--text);
}

.card-title-2 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text);
}

.card-post {
  margin: 0;
  font-size: 1rem;
  color: var(--primary);
}
.card-post-2 {
  margin: 0;
  font-size: 0.8rem;
  color: var(--primary);
}

.card-description {
  margin: 10px 0;
  font-size: 0.9rem;
  color: var(--text);
}
.card-description-2 {
  margin: 8px 0;
  font-size: 0.7rem;
  color: var(--text);
}

.card-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0.75rem 0;
}
.card-icons svg:hover {
  color: var(--accent);
}

.card-icons a {
  color: var(--text);
  font-size: 1.2em;
}

.card-icons-2 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1.1rem;
}

.card-icons-2 a {
  color: var(--background);
  font-size: 1.1em;
}

@media (max-width: 768px) {
}
