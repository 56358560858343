.redirect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background: linear-gradient(
    135deg,
    var(--background) 10%,
    var(--primary) 100%
  );
  font-family: "Oxanium", sans-serif;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.redirect-content {
  max-width: 500px;
  background: rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h2 {
  margin-bottom: 15px;
  font-size: 24px;
}

p {
  font-size: 16px;
}

a {
  color: var(--text);
  text-decoration: underline;
}

a:hover {
  color: var(--background);
}
