@import url("./App.css");
@font-face {
  font-family: "Westminster";
  src: url("./fonts/Westminster.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.home {
  background-color: var(--background);
  margin: 0;
  color: var(--text);
  font-family: "Oxanium", sans-serif;
  background: var(--background);
  display: flex;
  flex-direction: column;
}

section {
  height: 90vh;

  position: relative;
  overflow: hidden;
  z-index: 3;

  scroll-snap-type: y mandatory;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section .hero {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
}
section .hero h1 {
  font-size: 15vw;
  margin: 0;
  margin-top: -10px;

  font-family: "Westminster", sans-serif;
  letter-spacing: 0.08em;
  font-weight: 400;

  padding: 0;
}
section .hero p span {
  font-family: "Istok Web", sans-serif;
  font-size: 1.6vw;
  font-weight: 600;
  color: var(--text);

  margin-top: -15px;
}
section .hero h3 {
  font-size: 2em;
  font-weight: 700;
  margin: none;
  color: var(--primary);
}

section .hero button {
  position: relative;
  border: none;
  outline: none;

  font-family: "Oxanium", sans-serif;
  font-size: 1.6em;
  font-weight: 800;

  text-decoration: none;

  margin: 0.2em;
  padding: 0.6em 1em;

  background-color: var(--background);
  color: var(--primary);

  border: 0.1em solid var(--primary);
  z-index: 5;

  transition: 0.4s all ease-in-out;
}
section .hero a button {
  text-decoration: none;
  color: var(--primary);
}
section .hero a button:active {
  color: var(--primary);
}
section .hero a button:hover {
  cursor: pointer;
  background-color: var(--primary);
  color: var(--text);
  margin-top: -0.2em;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.area {
  width: 100vw;
  height: 1%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  margin-top: -10px;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: var(--fill);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.circles li:nth-child(11) {
  left: 85%;
  width: 50px;
  height: 50px;
  animation-delay: 4s;
  animation-duration: 8s;
}
.circles li:nth-child(12) {
  left: 85%;
  width: 200px;
  height: 200px;
  animation-delay: 6s;
  animation-duration: 12s;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.scroll-icon {
  font-size: 2rem;
  animation: bob 1.5s infinite;
  display: inline-block;
  margin: 20px;
}
.scroll-icon svg {
  fill: var(--text);
}
@keyframes bob {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
.about {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  margin: 10px 30px;
  margin-top: 10px;
}
.about .text {
  width: 75%;
  font-size: 0.8em;
  text-align: left;
  font-family: "Poppins", sans-serif;
}
.about .text h1 {
  color: var(--primary);
}
.about .imag img {
  width: 500px;
  margin-top: -40px;
}
.about .btns {
  margin: 20px 0;
}
.about .btns button {
  border: none;
  padding: 20px;
  margin: 0 25px 15px 0;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.about .btns .ws {
  background-color: rgb(84, 244, 84);
}
.about .btns i {
  font-size: 16px;
}
.about .btns .dc {
  background-color: #2c2f33;
  margin-right: 0;
}
.about .btns button:hover {
  filter: brightness(120%);
  cursor: pointer;
  transform: translateY(-10px);
}
.teaser {
  height: 55vw;

  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teaser h1 {
  color: var(--primary);
}
.teaser video {
  position: relative;
  width: 80%;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 900px) {
  .hero .typetext span {
    font-size: 16px;
    margin: 0;
  }
  .hero .title {
    font-size: 96px;
  }
  .hero .date {
    font-size: 20px;
  }
  .hero .btn button {
    font-size: 20px;
  }
  .about {
    height: 100vh;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin: 0;
    margin-bottom: 25px;
    order: 1;
  }
  .about .imag img {
    width: 300px;
    margin-top: 0;
  }
  .about h1 {
    font-size: h1;
  }
  .about .text {
    text-align: center;
  }
  .about {
    font-size: 1.2em;
  }
  .teaser {
    order: 2;
  }
  .teaser h1 {
    font-size: 1.2em;
  }
}
@media (max-width: 750px) {
  .teaser {
    margin-top: 60px;
  }
  .about {
    font-size: 1em;
  }
  .about .btns button {
    font-size: 0.8em;
    padding: 10px;
    border-radius: 5px;
  }
}
