@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  background-color: var(--background);
  color: var(--text);
  padding: 0;

  font-family: "Oxanium", sans-serif;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 10px;
}
a {
  text-decoration: none !important;
}
.box {
  display: flex;
  width: 500px;
  height: 250px;
  border-radius: 8px;
  background-color: var(--faint);
  box-shadow: 0px 4px 5px var(--box);
  margin: 30px;
  overflow: hidden;
  transition: 0.1s all ease-in-out;
}
.box .card-image img {
  width: 180px !important;
  height: 100%;
  object-fit: cover;
}

.box .card-content {
  position: relative;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.box .card-title {
  font-size: 30px;
  margin: 0;
  color: var(--text);
  margin-bottom: 16px;
  align-self: flex-start;
  padding-top: 16px;
}

.box .card-description {
  position: absolute;
  text-align: left;
  color: var(--txt2);
  font-family: "Poppins", sans-serif;
  padding: 0;
  top: 120px;
  left: 30px;
  font-weight: 400;
  font-size: 16px;
  margin-right: 20px;
  transform: translateY(-50%);
}

.box .card-content .btn {
  position: absolute;
  padding: 10px 20px;
  background-color: var(--accent);
  color: white;
  border: none;
  border-radius: 4px;
  bottom: 20px;
  right: 25px;
  cursor: pointer;
  text-transform: uppercase;
  align-self: flex-end;
  font-family: "Oxanium", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.box .card-content .btn:hover {
  background-color: var(--primary);
}

.intro .p1 {
  margin: 0 20%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.box:hover {
  transform: translateY(-10px);
}

.events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.intro h1 {
  font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 60px;
  padding: 30px 0;
}
.intro p {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  margin: 0 20%;
  margin-bottom: 20px;
}
.img1 {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 300px;
  left: 0;
  z-index: -1;
  transform: rotate(20deg);
}

.img2 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 800px;
  right: -60px;
  z-index: -1;
  transform: rotate(-20deg);
}

.img3 {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 1200px;
  left: -80px;
  z-index: -1;
  transform: rotate(10deg);
}

@media (max-width: 600px) {
  .intro h1 {
    font-size: 40px;
  }
  body {
    width: 150vw;
  }
}
