.footer {
  background-color: var(--background);
  color: var(--text);
  text-align: center;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer .social-media a {
  margin: 0 15px;
  color: var(--text);
  font-size: 24px;
  transition: color 0.3s;
}

.footer .social-media a:hover {
  color: var(--primary);
  margin-top: -20px;
}

.footer p {
  margin-top: 15px;
  font-size: 14px;
}
