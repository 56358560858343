.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.contact-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 80vh;
  justify-content: center;
  text-align: center;
  margin-bottom: 0px;
}

.map-container {
  background-color: var(--background);
  padding: 50px;
  margin: 60px;

  justify-content: center;
}
strong {
  color: var(--primary);
  font-size: 1.2em;
}
.text-container {
  margin: 25px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  background-color: var(--background);
  text-align: center;
}

.text-container h2 {
  margin-bottom: 20px;
}

.text-container p {
  margin: 10px 0;
}
.contact-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  align-items: center;
  color: var(--text);
}
.contact-boxes .boxy {
  background-color: var(--box);
  height: 100%;
  align-items: center;
  font-size: 0.8em;
  padding: 20px;

  border-radius: 8px;
  background-color: var(--faint);
  box-shadow: 0px 4px 5px var(--box);
  margin: 20px;

  overflow: hidden;
  transition: 0.1s all ease-in-out;
}
.contact-boxes .boxy:hover {
  transform: translateY(-10px);
}
@media (max-width: 1000px) {
  .contact-container {
    margin-top: 0;
    margin-bottom: 300px;
  }
  .map-container {
    margin-bottom: 0;
  }
}
