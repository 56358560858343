body {
  overflow-x: hidden;
  background-color: var(--background);
  color: var(--text);
}

.bodyalt {
  padding-top: 40px;
  padding-left: 40px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oxanium:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.primary .link {
  text-decoration: underline !important;
}

.primary .link:hover {
  color: var(--primary);
  cursor: pointer;
}
span {
  font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  color: var(--primary);
}

.intro div {
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
}

.intro h1 {
  font-size: 2.4rem;
  font-weight: 800;
  font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  margin: 10px 0;
}

h2 {
  font-size: 1.4rem;
  font-weight: 700;
  font-family: "Oxanium", sans-serif;
  margin: 0;
  margin-top: 25px;
  margin-bottom: 5px;
}

.secondary div,
.primary div {
  font-size: 1.1rem;
  padding-right: 40px;
}

.tertiary div {
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
}

.img {
  position: absolute;
  z-index: -1;
  top: -10px;
  right: -64px;
  height: 300px;
  transform: rotate(-20deg);
}
